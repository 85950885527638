import { useEffect } from "react";

export function usePageStyles(css: string) {
  return useEffect(() => {
    const styleEl = document.createElement("style");
    styleEl.type = "text/css";
    styleEl.appendChild(document.createTextNode(css));
    document.head.appendChild(styleEl);
    return () => {
      styleEl.remove();
    };
  }, [css]);
}
