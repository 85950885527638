import { MdPlayArrow, MdSettings, MdChatBubble, MdHelp } from "react-icons/md";
import { IconClient } from "../components/IconClient";
import { IconSession } from "../components/IconSession";
// import { IconParticipant } from "../components/IconParticipant";
import { IconTemplate } from "../components/IconTemplate";
import { IconSessionPlan } from "../components/IconSessionPlan";
import { IconIntervention } from "../components/IconIntervention";
import { IconMaterial } from "../components/IconMaterial";

export const links = [
  { to: "/sessions/new", text: "New Session", icon: MdPlayArrow },
  { to: "/sessions", text: "Document", icon: IconSession },
  { to: "/templates", text: "Templates", icon: IconTemplate },
  { to: "/session-plans", text: "Session Plans", icon: IconSessionPlan },
  { to: "/interventions", text: "Interventions", icon: IconIntervention },
  { to: "/materials", text: "Materials", icon: IconMaterial },
  { to: "/clients", text: "Clients", icon: IconClient },
  // { to: "/participants", text: "Participants", icon: IconParticipant },
];

export const otherLinks = [
  { to: "/account", text: "Account Settings", icon: MdSettings },
  {
    to: "https://blog.documt.com/tag/how-tos/",
    text: "How Tos",
    icon: MdHelp,
    isExternal: true,
  },
  {
    to: "/feedback",
    text: "Feedback",
    icon: MdChatBubble,
    appendCurrentLocation: true,
  },
];
