import * as React from "react";
import cx from "classcat";

type ClassName = string | undefined;
type Overrides = {
  style?: ClassName | Array<ClassName | boolean>;
};

export function View(
  props: Omit<Omit<React.ComponentProps<"div">, "className">, "style"> &
    Overrides
) {
  const { children, style, ...rest } = props;
  const className = Array.isArray(style) ? cx(style) : style;
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}
