import "@reach/dialog/styles.css";
import * as React from "react";
import {
  AlertDialogOverlay as RAlertDialogOverlay,
  AlertDialogContent as RAlertDialogContent,
  AlertDialogLabel as RAlertDialogLabel,
  AlertDialogDescription as RAlertDialogDescription,
} from "@reach/alert-dialog";
import { MdClose } from "react-icons/md";
import { View } from "./View";
import styles from "./AlertDialogDark.module.css";
import { ActivityIndicator } from "./ActivityIndicator";
import { Delay } from "./Delay";

export function AlertDialogOverlay(
  props: React.ComponentProps<typeof RAlertDialogOverlay>
) {
  return <RAlertDialogOverlay className={styles.overlay} {...props} />;
}

export function AlertDialogContent(
  props: React.ComponentProps<typeof RAlertDialogContent>
) {
  return <RAlertDialogContent className={styles.content} {...props} />;
}

export function AlertDialogLabel(
  props: React.ComponentProps<typeof RAlertDialogLabel>
) {
  return <RAlertDialogLabel className={styles.alertDialogLabel} {...props} />;
}

export function AlertDialogDescription(
  props: React.ComponentProps<typeof RAlertDialogDescription>
) {
  return <RAlertDialogDescription {...props} />;
}

export function AlertDialogHeader(props: { children: any }) {
  return <View style={styles.alertDialogHeader}>{props.children}</View>;
}

export function AlertDialogBody(props: { children: any }) {
  return <View style={styles.alertDialogBody}>{props.children}</View>;
}

export function AlertDialogActions(props: { children: any }) {
  return <View style={styles.alertDialogActions}>{props.children}</View>;
}

export function AlertDialogCloseButton(props: {
  onClick: NonNullable<React.ComponentProps<"button">["onClick"]>;
  positioned?: boolean;
}) {
  const { onClick, positioned = false } = props;
  const button = (
    <button onClick={onClick} aria-label="Close" className={styles.closeButton}>
      <MdClose />
    </button>
  );
  if (!positioned) {
    return button;
  }
  return <div className={styles.closeButtonContainer}>{button}</div>;
}

export function AlertDialogLoading() {
  const leastDestructiveRef = React.useRef<any>(null);
  return (
    <AlertDialogOverlay leastDestructiveRef={leastDestructiveRef}>
      <Delay>
        <RAlertDialogContent className={styles.alertDialogLoadingContainer}>
          <RAlertDialogLabel />
          <View style={styles.alertDialogLoading}>
            <ActivityIndicator />
          </View>
        </RAlertDialogContent>
      </Delay>
    </AlertDialogOverlay>
  );
}
