/* eslint-disable */

import * as qs from "qs";
class CustomError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}
export class SDKResponseError extends CustomError {
    constructor(error, status) {
        super("Bad response");
        this.error = error;
        this.status = status;
    }
}
export class SDK {
    constructor(opts) {
        this.deleteBusiness = function (params, query, opts) {
            let path = "/v2/businesses/:businessUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteClient = function (params, query, opts) {
            let path = "/v2/clients/:clientUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeTagFromClient = function (params, query, opts) {
            let path = "/v2/clients/:clientUuid/tags/:tagUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteDomainItem = function (params, query, opts) {
            let path = "/v2/domain-items/:domainItemUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteIntervention = function (params, query, opts) {
            let path = "/v2/interventions/:interventionUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeTagFromIntervention = function (params, query, opts) {
            let path = "/v2/interventions/:interventionUuid/tags/:tagUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteMaterial = function (params, query, opts) {
            let path = "/v2/materials/:materialUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeTagFromMaterial = function (params, query, opts) {
            let path = "/v2/materials/:materialUuid/tags/:tagUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteParticipantReportDomain = function (params, query, opts) {
            let path = "/v2/participant-reporting/:participantReportUuid/domains/:domainUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteParticipant = function (params, query, opts) {
            let path = "/v2/participants/:participantUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteSessionPlan = function (params, query, opts) {
            let path = "/v2/session-plans/:sessionPlanUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeInterventionFromSessionPlan = function (params, query, opts) {
            let path = "/v2/session-plans/:sessionPlanUuid/interventions/:interventionUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteInterventionSessionPlan = function (params, query, opts) {
            let path = "/v2/session-plans/:sessionPlanUuid/session-interventions/:interventionSessionPlanUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeTagFromSessionPlan = function (params, query, opts) {
            let path = "/v2/session-plans/:sessionPlanUuid/tags/:tagUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteSession = function (params, query, opts) {
            let path = "/v2/sessions/:sessionUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeTagFromSession = function (params, query, opts) {
            let path = "/v2/sessions/:sessionUuid/tags/:tagUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeBusinessFromUser = function (params, query, opts) {
            let path = "/v2/sharing/users/:userUuid/businesses/:businessUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeClientFromUser = function (params, query, opts) {
            let path = "/v2/sharing/users/:userUuid/clients/:clientUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeInterventionFromUser = function (params, query, opts) {
            let path = "/v2/sharing/users/:userUuid/interventions/:interventionUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteTag = function (params, query, opts) {
            let path = "/v2/tags/:tagUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteTemplate = function (params, query, opts) {
            let path = "/v2/templates/:templateUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteTemplateDomain = function (params, query, opts) {
            let path = "/v2/templates/:templateUuid/domains/:domainUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeTagFromTemplate = function (params, query, opts) {
            let path = "/v2/templates/:templateUuid/tags/:tagUuid";
            return this._delete(path, params, query, opts);
        };
        this.__setBaseUrl(opts.baseUrl);
        this._queryStringOpts = opts.queryStringOpts;
        if (opts?.headers) {
            this._headers = opts.headers ?? {};
        }
    }
    __setBaseUrl(baseUrl) {
        this._baseUrl = baseUrl;
    }
    __setHeaders(fn) {
        this._headers = fn(this._headers);
    }
    __clearCache() { }
    acceptInvite(params, data, query, opts) {
        let path = "/v2/accept-invite/:inviteUuid";
        return this._post(path, params, data, query, opts, undefined);
    }
    getBusinessList(params, query, opts) {
        let path = "/v2/businesses";
        return this._get(path, params, query, opts);
    }
    createBusiness(params, data, query, opts) {
        let path = "/v2/businesses";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getBusiness(params, query, opts) {
        let path = "/v2/businesses/:businessUuid";
        return this._get(path, params, query, opts);
    }
    updateBusiness(params, data, query, opts) {
        let path = "/v2/businesses/:businessUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getBusinessSessionList(params, query, opts) {
        let path = "/v2/businesses/:businessUuid/sessions";
        return this._get(path, params, query, opts);
    }
    getClientList(params, query, opts) {
        let path = "/v2/clients";
        return this._get(path, params, query, opts);
    }
    createClient(params, data, query, opts) {
        let path = "/v2/clients";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getClient(params, query, opts) {
        let path = "/v2/clients/:clientUuid";
        return this._get(path, params, query, opts);
    }
    updateClient(params, data, query, opts) {
        let path = "/v2/clients/:clientUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getParticipantList(params, query, opts) {
        let path = "/v2/clients/:clientUuid/participants";
        return this._get(path, params, query, opts);
    }
    getClientSessionList(params, query, opts) {
        let path = "/v2/clients/:clientUuid/sessions";
        return this._get(path, params, query, opts);
    }
    addTagToClient(params, data, query, opts) {
        let path = "/v2/clients/:clientUuid/tags";
        return this._post(path, params, data, query, opts, "application/json");
    }
    sendContactMessage(params, data, query, opts) {
        let path = "/v2/contact";
        return this._post(path, params, data, query, opts, "application/json");
    }
    downloadFile(params, query, opts) {
        let path = "/v2/downloads/:type/:uuid";
        return this._get(path, params, query, opts);
    }
    provideFeedback(params, data, query, opts) {
        let path = "/v2/feedback";
        return this._post(path, params, data, query, opts, "application/json");
    }
    sendPasswordResetEmail(params, data, query, opts) {
        let path = "/v2/forgot-password/:email";
        return this._post(path, params, data, query, opts, undefined);
    }
    getParticipantReportForClient(params, data, query, opts) {
        let path = "/v2/get-participant-report";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getReportForClient(params, data, query, opts) {
        let path = "/v2/get-report";
        return this._post(path, params, data, query, opts, "application/json");
    }
    runHealthCheck(params, query, opts) {
        let path = "/v2/health-check";
        return this._get(path, params, query, opts);
    }
    getInterventionList(params, query, opts) {
        let path = "/v2/interventions";
        return this._get(path, params, query, opts);
    }
    createIntervention(params, data, query, opts) {
        let path = "/v2/interventions";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getIntervention(params, query, opts) {
        let path = "/v2/interventions/:interventionUuid";
        return this._get(path, params, query, opts);
    }
    updateIntervention(params, data, query, opts) {
        let path = "/v2/interventions/:interventionUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    addTagToIntervention(params, data, query, opts) {
        let path = "/v2/interventions/:interventionUuid/tags";
        return this._post(path, params, data, query, opts, "application/json");
    }
    loginUser(params, data, query, opts) {
        let path = "/v2/login";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getMaterialList(params, query, opts) {
        let path = "/v2/materials";
        return this._get(path, params, query, opts);
    }
    createMaterial(params, data, query, opts) {
        let path = "/v2/materials";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getMaterial(params, query, opts) {
        let path = "/v2/materials/:materialUuid";
        return this._get(path, params, query, opts);
    }
    updateMaterial(params, data, query, opts) {
        let path = "/v2/materials/:materialUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    addTagToMaterial(params, data, query, opts) {
        let path = "/v2/materials/:materialUuid/tags";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getMe(params, query, opts) {
        let path = "/v2/me";
        return this._get(path, params, query, opts);
    }
    updateMe(params, data, query, opts) {
        let path = "/v2/me";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    updateMePassword(params, data, query, opts) {
        let path = "/v2/me/password";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    updateParticipantReportDomain(params, data, query, opts) {
        let path = "/v2/participant-reporting/:participantReportUuid/domains/:domainUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    upsertParticipantReportMultiSelectDomainValues(params, data, query, opts) {
        let path = "/v2/participant-reporting/:participantReportUuid/multi-select-domains/:domainUuid/values";
        return this._put(path, params, data, query, opts, "application/json");
    }
    getParticipantReportByUuid(params, query, opts) {
        let path = "/v2/participant-reports/:participantReportUuid";
        return this._get(path, params, query, opts);
    }
    createParticipant(params, data, query, opts) {
        let path = "/v2/participants";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getParticipant(params, query, opts) {
        let path = "/v2/participants/:participantUuid";
        return this._get(path, params, query, opts);
    }
    updateParticipant(params, data, query, opts) {
        let path = "/v2/participants/:participantUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    makePayment(params, data, query, opts) {
        let path = "/v2/payment";
        return this._post(path, params, data, query, opts, "application/json");
    }
    notifyPlatform(params, data, query, opts) {
        let path = "/v2/platform";
        return this._post(path, params, data, query, opts, "application/json");
    }
    registerUser(params, data, query, opts) {
        let path = "/v2/register";
        return this._post(path, params, data, query, opts, "application/json");
    }
    downloadFileReporting(params, query, opts) {
        let path = "/v2/reporting/downloads/:type/:uuid";
        return this._get(path, params, query, opts);
    }
    updateReport(params, data, query, opts) {
        let path = "/v2/reports/:reportUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    resetPassword(params, data, query, opts) {
        let path = "/v2/reset-password/:token";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getSessionPlanList(params, query, opts) {
        let path = "/v2/session-plans";
        return this._get(path, params, query, opts);
    }
    createSessionPlan(params, data, query, opts) {
        let path = "/v2/session-plans";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getSessionPlan(params, query, opts) {
        let path = "/v2/session-plans/:sessionPlanUuid";
        return this._get(path, params, query, opts);
    }
    updateSessionPlan(params, data, query, opts) {
        let path = "/v2/session-plans/:sessionPlanUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    copySessionPlan(params, data, query, opts) {
        let path = "/v2/session-plans/:sessionPlanUuid/copy";
        return this._post(path, params, data, query, opts, "application/json");
    }
    addInterventionToSessionPlan(params, data, query, opts) {
        let path = "/v2/session-plans/:sessionPlanUuid/interventions/:interventionUuid/add-to-session-plan";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    reorderSessionPlanInterventions(params, data, query, opts) {
        let path = "/v2/session-plans/:sessionPlanUuid/reorder-interventions";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getInterventionSessionPlanByUuid(params, query, opts) {
        let path = "/v2/session-plans/:sessionPlanUuid/session-interventions/:interventionSessionPlanUuid";
        return this._get(path, params, query, opts);
    }
    updateInterventionSessionPlan(params, data, query, opts) {
        let path = "/v2/session-plans/:sessionPlanUuid/session-interventions/:interventionSessionPlanUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    addTagToSessionPlan(params, data, query, opts) {
        let path = "/v2/session-plans/:sessionPlanUuid/tags";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getSessionList(params, query, opts) {
        let path = "/v2/sessions";
        return this._get(path, params, query, opts);
    }
    createSession(params, data, query, opts) {
        let path = "/v2/sessions";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getSession(params, query, opts) {
        let path = "/v2/sessions/:sessionUuid";
        return this._get(path, params, query, opts);
    }
    updateSession(params, data, query, opts) {
        let path = "/v2/sessions/:sessionUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    createReport(params, data, query, opts) {
        let path = "/v2/sessions/:sessionUuid/clients/:clientUuid/reports";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getParticipantReportsForSession(params, query, opts) {
        let path = "/v2/sessions/:sessionUuid/participant-reporting";
        return this._get(path, params, query, opts);
    }
    getParticipantReportForParticipantInSession(params, query, opts) {
        let path = "/v2/sessions/:sessionUuid/participants/:participantUuid/participant-reporting";
        return this._get(path, params, query, opts);
    }
    getReportBySession(params, query, opts) {
        let path = "/v2/sessions/:sessionUuid/reports";
        return this._get(path, params, query, opts);
    }
    addTagToSession(params, data, query, opts) {
        let path = "/v2/sessions/:sessionUuid/tags";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getInviteLists(params, query, opts) {
        let path = "/v2/sharing";
        return this._get(path, params, query, opts);
    }
    createInvite(params, data, query, opts) {
        let path = "/v2/sharing";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getTagList(params, query, opts) {
        let path = "/v2/tags";
        return this._get(path, params, query, opts);
    }
    createTag(params, data, query, opts) {
        let path = "/v2/tags";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getTag(params, query, opts) {
        let path = "/v2/tags/:tagUuid";
        return this._get(path, params, query, opts);
    }
    updateTag(params, data, query, opts) {
        let path = "/v2/tags/:tagUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getTemplateList(params, query, opts) {
        let path = "/v2/templates";
        return this._get(path, params, query, opts);
    }
    createTemplate(params, data, query, opts) {
        let path = "/v2/templates";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getTemplate(params, query, opts) {
        let path = "/v2/templates/:templateUuid";
        return this._get(path, params, query, opts);
    }
    updateTemplate(params, data, query, opts) {
        let path = "/v2/templates/:templateUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    copyTemplate(params, data, query, opts) {
        let path = "/v2/templates/:templateUuid/actions/copy";
        return this._put(path, params, data, query, opts, undefined);
    }
    createTemplateDomain(params, data, query, opts) {
        let path = "/v2/templates/:templateUuid/domains";
        return this._post(path, params, data, query, opts, "application/json");
    }
    updateTemplateDomain(params, data, query, opts) {
        let path = "/v2/templates/:templateUuid/domains/:domainUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    addTagToTemplate(params, data, query, opts) {
        let path = "/v2/templates/:templateUuid/tags";
        return this._post(path, params, data, query, opts, "application/json");
    }
    uploadFile(params, data, query, opts) {
        let path = "/v2/upload/:type";
        return this._post(path, params, data, query, opts, undefined);
    }
    uploadFileAttachment(params, data, query, opts) {
        let path = "/v2/upload/:type/:uuid";
        return this._post(path, params, data, query, opts, undefined);
    }
    getUser(params, query, opts) {
        let path = "/v2/users/:userUuid";
        return this._get(path, params, query, opts);
    }
    updateUser(params, data, query, opts) {
        let path = "/v2/users/:userUuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    async _get(path, params, query, opts) {
        const { hydratedPath } = findParamArguments(path, params);
        const url = `${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`;
        const cacheKey = `GET${url}`;
        let headers = { "Content-Type": "application/json" };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(url, { headers });
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        const out = { data: json, status: res.status };
        return out;
    }
    async _post(path, params, data, query, opts, requestBodyContentType) {
        const { hydratedPath } = findParamArguments(path, params);
        let headers = !requestBodyContentType
            ? {}
            : { "Content-Type": requestBodyContentType };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`, {
            method: "POST",
            headers,
            body: requestBodyContentType === "application/json"
                ? JSON.stringify(data)
                : data
        });
        if (res.status === 204) {
            return { data: undefined, status: res.status };
        }
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        return { data: json, status: res.status };
    }
    async _patch(path, params, data, query, opts, requestBodyContentType) {
        const { hydratedPath } = findParamArguments(path, params);
        let headers = !requestBodyContentType
            ? {}
            : { "Content-Type": requestBodyContentType };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`, {
            method: "PATCH",
            headers,
            body: requestBodyContentType === "application/json"
                ? JSON.stringify(data)
                : data
        });
        if (res.status === 204) {
            return { data: undefined, status: res.status };
        }
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        return { data: json, status: res.status };
    }
    async _put(path, params, data, query, opts, requestBodyContentType) {
        const { hydratedPath } = findParamArguments(path, params);
        let headers = !requestBodyContentType
            ? {}
            : { "Content-Type": requestBodyContentType };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`, {
            method: "PUT",
            headers,
            body: requestBodyContentType === "application/json"
                ? JSON.stringify(data)
                : data
        });
        if (res.status === 204) {
            return { data: undefined, status: res.status };
        }
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        return { data: json, status: res.status };
    }
    async _delete(path, params, query, opts) {
        const { hydratedPath } = findParamArguments(path, params);
        let headers = { "Content-Type": "application/json" };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`, { method: "DELETE", headers });
        if (res.status === 204) {
            return { data: undefined, status: res.status };
        }
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        return { data: json, status: res.status };
    }
}
function findParamArguments(path, obj) {
    let parts = path.split(/\//);
    let params = parts.filter(p => p.length > 0 && p[0] === ":");
    let args = params.map(p => p.substring(1, p.length));
    let out = {};
    let hydratedPath = path;
    if (obj) {
        args.forEach(e => {
            out[e] = obj[e];
            hydratedPath = hydratedPath.replace(`:${e}`, obj[e]);
        });
    }
    return { args, out, hydratedPath };
}
function generateQueryString(obj, _opts) {
    let opts = { addQueryPrefix: true, encode: true };
    if (_opts) {
        opts = { ...opts, ..._opts };
    }
    return qs.stringify(obj, opts);
}
