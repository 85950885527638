import * as React from "react";
import Logo from "../assets/logo.png";
import { PressableLink } from "./lib/PressableLink";
import { View } from "./lib/View";
import {
  HeaderMenu,
  HeaderMenuItemLayout,
  Menu,
  MenuList,
  MenuLink,
} from "./HeaderMenu";
import { HeaderMenuButton } from "./HeaderMenuButton";
import { links } from "../shared/links";
import { Link as RLink } from "react-router-dom";
import Media from "react-media";
import { SideMenu } from "./SideMenu";
import { HeaderNotifications } from "./HeaderNotifications";
import styles from "./Header.module.css";

const headerLinks = links.slice(0, 2);
const moreLinks = links.slice(2);

export function Header() {
  const logoLink = (
    <PressableLink to="/">
      <img src={Logo} alt="DocuMT" className={styles.logo} />
    </PressableLink>
  );
  return (
    <Media queries={{ large: "(min-width: 768px)" }}>
      {(matches) => (
        <>
          {matches.large ? null : <SideMenu />}
          <header className={styles.header}>
            <View style={styles.headerLayout}>
              {matches.large ? (
                <>
                  {logoLink}
                  <View style={styles.headerLinks}>
                    {headerLinks.map((x, i) => (
                      <PressableLink key={i} to={x.to}>
                        <View style={styles.headerLink}>
                          <x.icon />
                          <View>{x.text}</View>
                        </View>
                      </PressableLink>
                    ))}
                    <Menu>
                      <HeaderMenuButton>More</HeaderMenuButton>
                      <MenuList>
                        {moreLinks.map((x, i) => (
                          <MenuLink key={i} as={RLink} to={x.to}>
                            <HeaderMenuItemLayout>
                              <x.icon />
                              {x.text}
                            </HeaderMenuItemLayout>
                          </MenuLink>
                        ))}
                      </MenuList>
                    </Menu>
                  </View>
                </>
              ) : (
                <>
                  <View />
                  {logoLink}
                </>
              )}
              <View style={styles.headerEndLayout}>
                <HeaderNotifications />
                <HeaderMenu />
              </View>
            </View>
          </header>
        </>
      )}
    </Media>
  );
}
