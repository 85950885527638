import * as React from "react";
import { PressableLink } from "./lib/PressableLink";
import { View } from "./lib/View";
import { FlatList } from "./lib/FlatList";
import { links } from "../shared/links";
// @ts-ignore
import BurgerMenu from "react-burger-menu/lib/menus/slide";
import "./SideMenu.css";
import styles from "./SideMenu.module.css";

const sectionMarkers = ["Document", "Materials"];

export function SideMenu() {
  return (
    <BurgerMenu width={250}>
      <FlatList
        keyExtractor={(x, i) => String(i)}
        data={links}
        ItemSeparatorComponent={(x) =>
          sectionMarkers.includes(x.leadingItem.text) ? (
            <View style={styles.separator} />
          ) : null
        }
        renderItem={({ item, index }) => {
          return (
            <PressableLink key={index} to={item.to}>
              <View style={styles.sideMenuLink}>
                <item.icon />
                <View>{item.text}</View>
              </View>
            </PressableLink>
          );
        }}
      />
    </BurgerMenu>
  );
}
