import * as React from "react";
import cx from "classcat";
import styles from "./ButtonLayout.module.css";

export function ButtonLayout(props: {
  size?: "sm";
  children: React.ReactNode;
}) {
  return (
    <div
      className={cx([
        styles.buttonLayout,
        props.size === "sm" && styles.buttonLayoutSm,
      ])}
    >
      {props.children}
    </div>
  );
}
