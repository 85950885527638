import * as React from "react";
import { Header } from "./Header";
import { View } from "./lib/View";
import styles from "./LayoutTabs.module.css";
import { usePageStyles } from "../shared/usePageStyles";

export function LayoutTabs(props: { tabs: any; header?: any; children: any }) {
  usePageStyles(`body { background-color: white; }`);
  return (
    <>
      <Header />
      <View style={styles.top}>
        <View style={styles.limit}>
          {props.header}
          {props.tabs}
        </View>
      </View>
      <main className={styles.main}>
        <View style={styles.limit}>{props.children}</View>
      </main>
    </>
  );
}
