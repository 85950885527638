import * as React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useSDK } from "../shared/useSDK";
import { PageOneHeader } from "./PageOneHeader";
import { PageOneHeading } from "./PageOneHeading";

export function HeaderBusiness() {
  return (
    <PageOneHeader>
      <Heading />
    </PageOneHeader>
  );
}

function Heading() {
  const params = useParams<{ uuid: string }>();
  const { sdk } = useSDK();
  const result = useQuery(["business", params.uuid], (key, uuid: string) =>
    sdk.getBusiness({ businessUuid: uuid })
  );
  const fallbackText = "Business";
  if (result.status === "loading") {
    // To maintain constant height
    return <PageOneHeading>{"\u00a0"}</PageOneHeading>;
  }
  if (result.status === "error" || result.data === undefined) {
    return <PageOneHeading>{fallbackText}</PageOneHeading>;
  }
  return <PageOneHeading>{result.data.data.name}</PageOneHeading>;
}
