import * as React from "react";

export const AuthContext = React.createContext<{
  token: string;
  signIn: (token: string, cb?: () => void) => void;
  signOut: () => void;
}>(
  // @ts-ignore
  undefined
);
