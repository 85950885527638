import * as React from "react";
import { useQuery, useMutation, queryCache } from "react-query";
import { Menu, MenuItems, MenuItem, MenuLink } from "./lib/MenuButton";
import { Link as RLink } from "react-router-dom";
import { GoCheck } from "react-icons/go";
import { useHistory, useLocation } from "react-router-dom";
import { View } from "./lib/View";
import { Text } from "./lib/Text";
import { Link } from "./lib/Link";
import { showToast } from "./lib/Toast";
import { FlatList } from "./lib/FlatList";
import { otherLinks } from "../shared/links";
import { useAuth } from "../shared/useAuth";
import { useSDK } from "../shared/useSDK";
import { useUser } from "../shared/useUser";
import styles from "./HeaderMenu.module.css";
import { Delay } from "./lib/Delay";
import { ActivityIndicator } from "./lib/ActivityIndicator";
import { PressableLink } from "./lib/PressableLink";
import { Avatar } from "./lib/Avatar";
import { IconSettings } from "./IconSettings";
import { redirectLocationKey } from "../shared/redirectLocationKey";
import { HeaderMenuButton } from "./HeaderMenuButton";
import { HeaderMenuPopover } from "./HeaderMenuPopover";

export { Menu, MenuList, MenuItem, MenuLink } from "./lib/MenuButton";

export function HeaderMenu() {
  const location = useLocation();
  const { signOut } = useAuth();
  const { user } = useUser();
  return (
    <Menu>
      <HeaderMenuButton>
        <Avatar
          src={
            // @ts-ignore
            user.avatarImageUrl
          }
          fallback={
            // @ts-ignore
            user.firstName
          }
          size="xs"
        />
      </HeaderMenuButton>
      <HeaderMenuPopover>
        <HeaderMenuUserSection />
        <HeaderMenuBusinesses />
        <View style={styles.section}>
          <MenuItems>
            {otherLinks.map((x, i) => {
              if (x.isExternal) {
                return (
                  <MenuLink
                    key={i}
                    href={x.to}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {x.text}
                  </MenuLink>
                );
              }
              let to = x.to;
              if (x.appendCurrentLocation) {
                to +=
                  "?" +
                  new URLSearchParams({
                    [redirectLocationKey]: location.pathname + location.search,
                  }).toString();
              }
              return (
                <MenuLink key={i} as={RLink} to={to}>
                  {x.text}
                </MenuLink>
              );
            })}
          </MenuItems>
        </View>
        <View style={styles.signOutSection}>
          <MenuItem onSelect={signOut}>Sign Out</MenuItem>
        </View>
      </HeaderMenuPopover>
    </Menu>
  );
}

function HeaderMenuUserSection() {
  const { sdk } = useSDK();
  const meResult = useQuery("me", (key) => sdk.getMe());
  function _render() {
    if (meResult.status === "loading") {
      return <ActivityIndicator />;
    }
    if (meResult.status === "error" || meResult.data === undefined) {
      return <Text style={styles.userName}>User</Text>;
    }
    const me = meResult.data.data;
    return (
      <Text style={styles.userName}>
        {[me.firstName, me.lastName].join(" ")}
      </Text>
    );
  }
  return <View style={styles.userSection}>{_render()}</View>;
}

function HeaderMenuBusinesses() {
  const location = useLocation();
  const history = useHistory();
  const { sdk } = useSDK();
  const meResult = useQuery("me", (key) => sdk.getMe());
  const result = useQuery(["businessList"], (key) => sdk.getBusinessList());
  const [updateSelectedBusiness] = useMutation(
    (variables: { data: Parameters<typeof sdk.updateMe>[1] }) => {
      return sdk.updateMe(null, variables.data);
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData("me", data);
        if (location.pathname !== "/") {
          history.push("/");
        }
        showToast("Done!", { type: "success" });
      },
      onError: () => {
        showToast("Something went wrong. Please try again.", { type: "error" });
      },
    }
  );
  function _render() {
    if (result.status === "loading" || meResult.status === "loading") {
      return (
        <View style={styles.businessListLoading}>
          <Delay>
            <ActivityIndicator />
          </Delay>
        </View>
      );
    }
    if (
      result.status === "error" ||
      result.data === undefined ||
      meResult.status === "error" ||
      meResult.data === undefined
    ) {
      return (
        <View style={styles.businessListEmpty}>
          <Text>Failed to load businesses</Text>
        </View>
      );
    }
    const businessList = result.data.data.results;
    const me = meResult.data.data;
    return (
      <FlatList
        keyExtractor={(x) => x.uuid}
        data={businessList}
        ListEmptyComponent={
          <View style={styles.businessListEmpty}>
            <Text>No businesses yet</Text>
          </View>
        }
        renderItem={({ item }) => {
          const isSelected =
            item.uuid === (me.selectedBusinessUuid ?? me.primaryBusinessUuid);
          return (
            <View style={styles.business}>
              <MenuItem
                onSelect={() => {
                  updateSelectedBusiness({
                    data: { selectedBusinessUuid: item.uuid },
                  });
                }}
                className={styles.menuItemBusiness}
              >
                <GoCheck
                  className={styles.selectedIcon}
                  style={isSelected ? undefined : { color: "transparent" }}
                />
                <Avatar
                  src={item.logoUrl}
                  shape="rectangular"
                  fallback={item.name}
                  fallbackVariant="outline"
                  size="xs"
                />
                <Text style={styles.menuItemBusinessName}>{item.name}</Text>
              </MenuItem>
              {item.isOwner ? (
                <PressableLink to={`/businesses/${item.uuid}`}>
                  <View style={styles.settingButton}>
                    <IconSettings className={styles.settingIcon} />
                  </View>
                </PressableLink>
              ) : (
                <div style={{ opacity: 0 }}>
                  <View style={styles.settingButton}>
                    <IconSettings className={styles.settingIcon} />
                  </View>
                </div>
              )}
            </View>
          );
        }}
      />
    );
  }
  return (
    <View style={styles.businesses}>
      <View style={styles.businessesHeader}>
        <Text style={styles.businessHeading}>Businesses</Text>
        <Link
          to={
            "/businesses/new?" +
            new URLSearchParams({
              [redirectLocationKey]: location.pathname + location.search,
            }).toString()
          }
        >
          + New Business
        </Link>
      </View>
      <View style={styles.businessListContainer}>{_render()}</View>
    </View>
  );
}

export function HeaderMenuItemLayout(props: { children: any }) {
  return <View style={styles.menuItemLayout}>{props.children}</View>;
}
