import * as React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { IconClient } from "./IconClient";
import { useSDK } from "../shared/useSDK";
import {
  Breadcrumb,
  BreadcrumbAligner,
  BreadcrumbLink,
} from "./lib/Breadcrumb";

const listLink = {
  to: "/clients",
  text: "Clients",
  icon: <IconClient />,
};

export function BreadcrumbClient() {
  const params = useParams<{ uuid?: string }>();
  return (
    <BreadcrumbAligner>
      <Breadcrumb>
        <BreadcrumbLink to={listLink.to}>
          {listLink.icon}
          <span>{listLink.text}</span>
        </BreadcrumbLink>
        {params.uuid ? <BreadcrumbItem /> : null}
      </Breadcrumb>
    </BreadcrumbAligner>
  );
}

function BreadcrumbItem() {
  const params = useParams<{ uuid: string }>();
  const { sdk } = useSDK();
  const result = useQuery(["client", params.uuid], (key, uuid: string) =>
    sdk.getClient({ clientUuid: uuid })
  );
  const to = `/clients/${params.uuid}`;
  const fallbackText = "Client";
  if (result.status === "loading") {
    return null;
  }
  if (result.status === "error" || result.data === undefined) {
    return <BreadcrumbLink to={to}>{fallbackText}</BreadcrumbLink>;
  }
  return <BreadcrumbLink to={to}>{result.data.data.name}</BreadcrumbLink>;
}
