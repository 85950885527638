import * as React from "react";
import cx from "classcat";
import { Header } from "./Header";
import styles from "./Layout.module.css";

export function Layout(props: { children: React.ReactNode }) {
  return (
    <>
      <Header />
      <main
        className={cx([
          styles.main,
          // styles.mainLimit
        ])}
      >
        {props.children}
      </main>
    </>
  );
}
