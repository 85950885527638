import * as React from "react";
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  generatePath,
  useLocation,
} from "react-router-dom";
import { RouteProps } from "react-router-dom";
import { queryCache, useQuery } from "react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { SDK } from "./sdk";
import { SDKContext } from "./shared/SDKContext";
import { AuthContext } from "./shared/AuthContext";
import { UserContext } from "./shared/UserContext";
import { useSDK } from "./shared/useSDK";
import * as auth from "./shared/auth";
import { formatDocumentTitle } from "./shared/formatDocumentTitle";
import { DocumentTitle } from "./components/lib/DocumentTitle";
import { FullScreenLoading } from "./components/FullScreenLoading";
import { DefaultErrorBoundary } from "./components/DefaultErrorBoundary";
import { Layout } from "./components/Layout";
import { apiBaseUrl, reCaptchaSiteKey } from "./config";
import { NavTabList, NavTab } from "./components/NavTabs";
import { ErrorMessage } from "./components/ErrorMessage";
import { PageOneHeading } from "./components/PageOneHeading";
import { LayoutTabs } from "./components/LayoutTabs";
import { PageOneHeader } from "./components/PageOneHeader";
import { BreadcrumbClient } from "./components/BreadcrumbClient";
import { IconSettings } from "./components/IconSettings";
import { IconParticipant } from "./components/IconParticipant";
import { BreadcrumbSession } from "./components/BreadcrumbSession";
import { BreadcrumbTemplate } from "./components/BreadcrumbTemplate";
import { HeaderActionsTemplate } from "./components/HeaderActionsTemplate";
import { IconAccount } from "./components/IconAccount";
import { IconBilling } from "./components/IconBilling";
import { IconReporting } from "./components/IconReporting";
import { IconFile } from "./components/IconFile";
import { redirectLocationKey } from "./shared/redirectLocationKey";
import { BreadcrumbSessionPlan } from "./components/BreadcrumbSessionPlan";
import { IconInfo } from "./components/IconInfo";
import { showToast } from "./components/lib/Toast";
import { useUser } from "./shared/useUser";
import { HeaderBusiness } from "./components/HeaderBusiness";
import { ToastContainer } from "react-toastify";
import { BreadcrumbIntervention } from "./components/BreadcrumbIntervention";
import { BreadcrumbMaterial } from "./components/BreadcrumbMaterial";
import fromUnixTime from "date-fns/fromUnixTime";

const NotFound = React.lazy(() => import("./screens/404/404"));
const Login = React.lazy(() => import("./screens/Login/Login"));
const SignUp = React.lazy(() => import("./screens/SignUp/SignUp"));
const RequestPasswordReset = React.lazy(() =>
  import("./screens/RequestPasswordReset/RequestPasswordReset")
);
const PasswordReset = React.lazy(() =>
  import("./screens/PasswordReset/PasswordReset")
);
const ContactUs = React.lazy(() => import("./screens/ContactUs/ContactUs"));
const Terms = React.lazy(() => import("./screens/Terms/Terms"));
const PrivacyPolicy = React.lazy(() =>
  import("./screens/PrivacyPolicy/PrivacyPolicy")
);
const ReportData = React.lazy(() => import("./screens/ReportData/ReportData"));
const ReportParticipant = React.lazy(() =>
  import("./screens/ReportParticipant/ReportParticipant")
);
const Account = React.lazy(() => import("./screens/Account/Account"));
const Billing = React.lazy(() => import("./screens/Billing/Billing"));
const Home = React.lazy(() => import("./screens/Home/Home"));
const Welcome = React.lazy(() => import("./screens/Welcome/Welcome"));
const Feedback = React.lazy(() => import("./screens/Feedback/Feedback"));
const SessionCreate = React.lazy(() =>
  import("./screens/SessionCreate/SessionCreate")
);
const Sessions = React.lazy(() => import("./screens/Sessions/Sessions"));
const Session = React.lazy(() => import("./screens/Session/Session"));
const SessionDocumentation = React.lazy(() =>
  import("./screens/SessionDocumentation/SessionDocumentation")
);
const SessionReporting = React.lazy(() =>
  import("./screens/SessionReporting/SessionReporting")
);
const SessionSettings = React.lazy(() =>
  import("./screens/SessionSettings/SessionSettings")
);
const SessionPlans = React.lazy(() =>
  import("./screens/SessionPlans/SessionPlans")
);
const SessionPlanCreate = React.lazy(() =>
  import("./screens/SessionPlanCreate/SessionPlanCreate")
);
const SessionPlan = React.lazy(() =>
  import("./screens/SessionPlan/SessionPlan")
);
const SessionPlanSettings = React.lazy(() =>
  import("./screens/SessionPlanSettings/SessionPlanSettings")
);
const Interventions = React.lazy(() =>
  import("./screens/Interventions/Interventions")
);
const InterventionCreate = React.lazy(() =>
  import("./screens/InterventionCreate/InterventionCreate")
);
const InterventionSettings = React.lazy(() =>
  import("./screens/InterventionSettings/InterventionSettings")
);
const Materials = React.lazy(() => import("./screens/Materials/Materials"));
const MaterialCreate = React.lazy(() =>
  import("./screens/MaterialCreate/MaterialCreate")
);
const MaterialSettings = React.lazy(() =>
  import("./screens/MaterialSettings/MaterialSettings")
);
const ParticipantReportPrint = React.lazy(() =>
  import("./screens/ParticipantReportPrint/ParticipantReportPrint")
);
const ParticipantCreate = React.lazy(() =>
  import("./screens/ParticipantCreate/ParticipantCreate")
);
const Participant = React.lazy(() =>
  import("./screens/Participant/Participant")
);
const BusinessCreate = React.lazy(() =>
  import("./screens/BusinessCreate/BusinessCreate")
);
const Business = React.lazy(() => import("./screens/Business/Business"));
const BusinessSessions = React.lazy(() =>
  import("./screens/BusinessSessions/BusinessSessions")
);
const BusinessMembers = React.lazy(() =>
  import("./screens/BusinessMembers/BusinessMembers")
);
const BusinessMember = React.lazy(() =>
  import("./screens/BusinessMember/BusinessMember")
);
const ClientCreate = React.lazy(() =>
  import("./screens/ClientCreate/ClientCreate")
);
const Clients = React.lazy(() => import("./screens/Clients/Clients"));
const Client = React.lazy(() => import("./screens/Client/Client"));
const ClientParticipants = React.lazy(() =>
  import("./screens/ClientParticipants/ClientParticipants")
);
const ClientSettings = React.lazy(() =>
  import("./screens/ClientSettings/ClientSettings")
);
const TemplateCreate = React.lazy(() =>
  import("./screens/TemplateCreate/TemplateCreate")
);
const Templates = React.lazy(() => import("./screens/Templates/Templates"));
const TemplateMarketplace = React.lazy(() =>
  import("./screens/TemplateMarketplace/TemplateMarketplace")
);
const TemplateMarketplaceDetail = React.lazy(() =>
  import("./screens/TemplateMarketplaceDetail/TemplateMarketplaceDetail")
);
const TemplateDomains = React.lazy(() =>
  import("./screens/TemplateDomains/TemplateDomains")
);
const TemplateSettings = React.lazy(() =>
  import("./screens/TemplateSettings/TemplateSettings")
);

type RouteConfig = {
  path: string;
  title: string;
  // comp: React.LazyExoticComponent<() => JSX.Element>;
  comp: any;
  Layout: (props: any) => JSX.Element;
  layoutProps?: any;
  // Route?: (props: RouteProps) => JSX.Element;
  Route?: any;
};

export const routes = [
  {
    path: "/login",
    title: "Login",
    comp: <Login />,
    Layout: PublicLayout,
    Route: RedirectIfAuthenticatedRoute,
  },
  {
    path: "/signup",
    title: "Sign Up",
    comp: (
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
        <SignUp />
      </GoogleReCaptchaProvider>
    ),
    Layout: PublicLayout,
    Route: RedirectIfAuthenticatedRoute,
  },
  {
    path: "/request-password-reset",
    title: "Forgot Password",
    comp: <RequestPasswordReset />,
    Layout: PublicLayout,
    Route: RedirectIfAuthenticatedRoute,
  },
  {
    path: "/do-password-reset/:token",
    title: "Reset Password",
    comp: <PasswordReset />,
    Layout: PublicLayout,
    Route: RedirectIfAuthenticatedRoute,
  },
  {
    path: "/contact",
    title: "Contact Us",
    comp: <ContactUs />,
    Layout: PublicLayout,
    Route: Route,
  },
  {
    path: "/terms",
    title: "Terms",
    comp: <Terms />,
    Layout: PublicLayout,
    Route: Route,
  },
  {
    path: "/privacy-policy",
    title: "Privacy Policy",
    comp: <PrivacyPolicy />,
    Layout: PublicLayout,
    Route: Route,
  },
  {
    path: "/data-report",
    title: "Music Therapy Reports",
    comp: <ReportData />,
    Layout: PublicLayout,
    Route: Route,
  },
  {
    path: "/participant-report",
    title: "Music Therapy Reports",
    comp: <ReportParticipant />,
    Layout: PublicLayout,
    Route: Route,
  },
  ...createTabRoutes({
    title: "Account",
    tabs: [
      {
        path: "/account",
        text: "Account",
        title: "Account",
        icon: IconAccount,
        comp: <Account />,
      },
      {
        path: "/account/billing",
        text: "Billing",
        title: "Account Billing",
        icon: IconBilling,
        comp: <Billing />,
      },
    ],
    layoutProps: {
      header: (
        <PageOneHeader>
          <PageOneHeading>Manage Account</PageOneHeading>
        </PageOneHeader>
      ),
    },
  }),
  {
    path: "/",
    title: "Dashboard",
    comp: <Home />,
    Layout: Layout,
    Route: PrivateRoute,
  },
  {
    path: "/welcome",
    title: "Welcome",
    comp: <Welcome />,
    Layout: Layout,
    Route: PrivateRoute,
  },
  {
    path: "/feedback",
    title: "Feedback",
    comp: <Feedback />,
    Layout: Layout,
    Route: PrivateRoute,
  },
  {
    path: "/businesses/new",
    title: "New Business",
    comp: <BusinessCreate />,
    Layout,
    Route: PrivateRoute,
  },
  ...createTabRoutes({
    title: "Business",
    tabs: [
      {
        path: "/businesses/:uuid",
        text: "Settings",
        title: "Business",
        icon: IconSettings,
        comp: <Business />,
      },
      {
        path: "/businesses/:uuid/sessions",
        text: "Sessions",
        title: "Business Sessions",
        icon: IconParticipant,
        comp: <BusinessSessions />,
      },
      {
        path: "/businesses/:uuid/members",
        text: "Members",
        title: "Business Members",
        icon: IconParticipant,
        comp: <BusinessMembers />,
      },
    ],
    filterTabListPred: (user) => (tab) => {
      if (user.isBusinessAccount) {
        return true;
      }
      return ["/businesses/:uuid"].includes(tab.path);
    },
    layoutProps: {
      header: <HeaderBusiness />,
    },
  }),
  {
    path: "/businesses/:businessUuid/members/:memberUuid",
    title: "Member",
    comp: <BusinessMember />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/sessions/new",
    title: "New Session",
    comp: <SessionCreate />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/sessions",
    title: "Sessions",
    comp: <Sessions />,
    Layout,
    Route: PrivateRoute,
  },
  ...createTabRoutes({
    title: "Session",
    tabs: [
      {
        path: "/sessions/:uuid",
        text: "Overview",
        title: "Session",
        icon: IconInfo,
        comp: <Session />,
      },
      {
        path: "/sessions/:uuid/documentation",
        text: "Document",
        title: "Session Documentation",
        icon: IconFile,
        comp: <SessionDocumentation />,
      },
      {
        path: "/sessions/:uuid/reporting",
        text: "Reporting",
        title: "Session Reporting",
        icon: IconReporting,
        comp: <SessionReporting />,
      },
      {
        path: "/sessions/:uuid/settings",
        text: "Settings",
        title: "Session Settings",
        icon: IconSettings,
        comp: <SessionSettings />,
      },
    ],
    layoutProps: {
      header: (
        <PageOneHeader>
          <BreadcrumbSession />
        </PageOneHeader>
      ),
    },
  }),
  {
    path: "/session-plans",
    title: "Session Plans",
    comp: <SessionPlans />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/session-plans/new",
    title: "New Session Plan",
    comp: <SessionPlanCreate />,
    Layout,
    Route: PrivateRoute,
  },
  ...createTabRoutes({
    title: "Session Plan",
    tabs: [
      {
        path: "/session-plans/:uuid",
        text: "Overview",
        title: "Session Plan",
        icon: IconInfo,
        comp: <SessionPlan />,
      },
      {
        path: "/session-plans/:uuid/settings",
        text: "Settings",
        title: "Session Plan Settings",
        icon: IconSettings,
        comp: <SessionPlanSettings />,
      },
    ],
    layoutProps: {
      header: (
        <PageOneHeader>
          <BreadcrumbSessionPlan />
        </PageOneHeader>
      ),
    },
  }),
  {
    path: "/interventions",
    title: "Interventions",
    comp: <Interventions />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/interventions/new",
    title: "New Intervention",
    comp: <InterventionCreate />,
    Layout,
    Route: PrivateRoute,
  },
  ...createTabRoutes({
    title: "Intervention",
    tabs: [
      {
        path: "/interventions/:uuid",
        text: "Settings",
        title: "Intervention Settings",
        icon: IconSettings,
        comp: <InterventionSettings />,
      },
    ],
    layoutProps: {
      header: (
        <PageOneHeader>
          <BreadcrumbIntervention />
        </PageOneHeader>
      ),
    },
  }),
  {
    path: "/materials",
    title: "Materials",
    comp: <Materials />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/materials/new",
    title: "New Material",
    comp: <MaterialCreate />,
    Layout,
    Route: PrivateRoute,
  },
  ...createTabRoutes({
    title: "Material",
    tabs: [
      {
        path: "/materials/:uuid",
        text: "Settings",
        title: "Material Settings",
        icon: IconSettings,
        comp: <MaterialSettings />,
      },
    ],
    layoutProps: {
      header: (
        <PageOneHeader>
          <BreadcrumbMaterial />
        </PageOneHeader>
      ),
    },
  }),
  {
    path: "/participant-reports/:uuid/print",
    title: "Printable Participant Report",
    comp: <ParticipantReportPrint />,
    Layout: (props: { children: any }) => props.children,
    Route: PrivateRoute,
  },
  {
    path: "/clients/new",
    title: "New Client",
    comp: <ClientCreate />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/clients",
    title: "Clients",
    comp: <Clients />,
    Layout,
    Route: PrivateRoute,
  },
  ...createTabRoutes({
    title: "Client",
    tabs: [
      {
        path: "/clients/:uuid",
        text: "Overview",
        title: "Client",
        icon: IconInfo,
        comp: <Client />,
      },
      {
        path: "/clients/:uuid/participants",
        text: "Participants",
        title: "Client Participants",
        icon: IconParticipant,
        comp: <ClientParticipants />,
      },
      {
        path: "/clients/:uuid/info",
        text: "Info",
        title: "Client Info",
        icon: IconSettings,
        comp: <ClientSettings />,
      },
    ],
    layoutProps: {
      header: (
        <PageOneHeader>
          <BreadcrumbClient />
        </PageOneHeader>
      ),
    },
  }),
  {
    path: "/participants/new",
    title: "New Participant",
    comp: <ParticipantCreate />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/participants/:uuid",
    title: "Participant",
    comp: <Participant />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/templates/new",
    title: "New Template",
    comp: <TemplateCreate />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/templates/marketplace",
    title: "Template Marketplace",
    comp: <TemplateMarketplace />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/templates/marketplace/:uuid",
    title: "Template",
    comp: <TemplateMarketplaceDetail />,
    Layout,
    Route: PrivateRoute,
  },
  {
    path: "/templates",
    title: "Templates",
    comp: <Templates />,
    Layout,
    Route: PrivateRoute,
  },
  ...createTabRoutes({
    title: "Template",
    tabs: [
      {
        path: "/templates/:uuid",
        text: "Settings",
        title: "Template Settings",
        icon: IconSettings,
        comp: <TemplateSettings />,
      },
      {
        path: "/templates/:uuid/domains",
        text: "Domains",
        title: "Template Domains",
        icon: IconParticipant,
        comp: <TemplateDomains />,
      },
    ],
    layoutProps: {
      header: (
        <PageOneHeader>
          <BreadcrumbTemplate />
          <HeaderActionsTemplate />
        </PageOneHeader>
      ),
    },
  }),
] as Array<RouteConfig>;

type Tab = {
  path: string;
  text: string;
  icon?: any;
  title?: string;
  // comp: React.LazyExoticComponent<() => JSX.Element>;
  comp: any;
};

function createTabRoutes(input: {
  title: string;
  tabs: Array<Tab>;
  layoutProps?: any;
  propagateQueryParams?: boolean;
  filterTabListPred?: (user: any) => (x: Tab, i: number) => boolean;
}): Array<RouteConfig> {
  const {
    tabs,
    layoutProps = {},
    propagateQueryParams = false,
    filterTabListPred,
  } = input;
  return tabs.map((x) => ({
    path: x.path,
    title: x.title ?? input.title,
    comp: x.comp,
    Layout: (props: { children: any }) => {
      const routeMatch = useRouteMatch({
        path: x.path,
        strict: true,
        sensitive: true,
      });
      const { user } = useUser();
      let filtereredTabs = tabs;
      if (filterTabListPred) {
        filtereredTabs = tabs.filter(filterTabListPred(user));
      }
      return (
        <LayoutTabs
          tabs={
            <NavTabList>
              {filtereredTabs.map((x, i) => {
                let to = generatePath(x.path, routeMatch?.params ?? {});
                if (propagateQueryParams) {
                  to += window.location.search;
                }
                return (
                  <NavTab key={i} to={to}>
                    {x.icon == null ? null : <x.icon />}
                    {x.text}
                  </NavTab>
                );
              })}
            </NavTabList>
          }
          {...layoutProps}
        >
          {props.children}
        </LayoutTabs>
      );
    },
  }));
}

function PublicLayout(props: { children: any }) {
  return props.children;
}

export default function App() {
  const token = auth.getToken();
  const sdk = new SDK({ baseUrl: apiBaseUrl });
  if (token != null) {
    sdk.__setHeaders((x: any) => ({ ...x, Authorization: `Bearer ${token}` }));
  }
  const signIn = (token: string, cb?: () => void) => {
    auth.setToken(token);
    sdk.__setHeaders((x: any) => ({
      ...x,
      Authorization: `Bearer ${token}`,
    }));
    cb?.();
  };
  const signOut = () => {
    window.localStorage.clear();
    queryCache.clear();
    window.location.href = "/login";
  };
  return (
    <DefaultErrorBoundary>
      <ToastContainer />
      <AuthContext.Provider
        value={{ token: auth.getToken() as string, signIn, signOut }}
      >
        <SDKContext.Provider value={{ sdk }}>
          <BrowserRouter>
            <React.Suspense fallback={<FullScreenLoading />}>
              <Switch>
                <Redirect from="/register" to="/signup" />
                {routes.map((x, i) => {
                  const RouteComp = x.Route ?? PrivateRoute;
                  return (
                    <RouteComp key={i} exact path={x.path}>
                      <DocumentTitle>
                        {formatDocumentTitle(x.title)}
                      </DocumentTitle>
                      <x.Layout {...x.layoutProps}>
                        <React.Suspense fallback={<FullScreenLoading />}>
                          {x.comp}
                        </React.Suspense>
                      </x.Layout>
                    </RouteComp>
                  );
                })}
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </SDKContext.Provider>
      </AuthContext.Provider>
    </DefaultErrorBoundary>
  );
}

// If already authenticated, redirect to `/` or `to`
function RedirectIfAuthenticatedRoute(props: RouteProps) {
  const { children, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.isAuthenticated()) {
          return children;
        }
        const searchParams = new URLSearchParams(props.location.search);
        const redirectLocation = searchParams.get(redirectLocationKey) || "/";
        return <Redirect to={redirectLocation} />;
      }}
    />
  );
}

function PrivateRoute(props: RouteProps) {
  const { children, ...rest } = props;
  const token = auth.getToken();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token == null) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                search: new URLSearchParams({
                  [redirectLocationKey]:
                    props.location.pathname + props.location.search,
                }).toString(),
              }}
            />
          );
        }
        const content = (
          <UserProvider>
            {(user: any) => (
              <UserContext.Provider value={{ user }}>
                {children}
              </UserContext.Provider>
            )}
          </UserProvider>
        );
        if (
          rest.location?.pathname &&
          ["/account/billing", "/businesses/new", "/welcome"].includes(
            rest.location.pathname
          )
        ) {
          // We don't want to wrap with
          // EnsureTrialNotExpiredAndHasPrimaryBusiness
          return content;
        }
        return (
          <EnsureTrialNotExpiredAndHasPrimaryBusiness>
            {content}
          </EnsureTrialNotExpiredAndHasPrimaryBusiness>
        );
      }}
    />
  );
}

function UserProvider(props: { children: any }) {
  const { sdk } = useSDK();
  const result = useQuery("me", (key) => sdk.getMe());
  if (result.status === "loading") {
    return <FullScreenLoading />;
  }
  if (result.status === "error" || result.data === undefined) {
    return <ErrorMessage error={result.error} />;
  }
  const user = result.data.data;
  return props.children(user);
}

function EnsureTrialNotExpiredAndHasPrimaryBusiness(props: { children: any }) {
  const location = useLocation();
  const { sdk } = useSDK();
  const result = useQuery("me", (key) =>
    sdk.getMe().then((result) => {
      const me = result.data;
      if (
        me.isPaid &&
        me.selectedBusinessUuid == null &&
        me.businesses.length > 0
      ) {
        const selectedBusinessUuid = me.businesses[0].uuid;
        return sdk
          .updateMe(null, { selectedBusinessUuid })
          .then((updateResult) => {
            queryCache.setQueryData("me", updateResult);
            return result;
          });
      }
      return result;
    })
  );
  if (result.status === "loading") {
    return <FullScreenLoading />;
  }
  if (
    result.status === "error" &&
    result.data?.status === 401 &&
    location.pathname !== "/login"
  ) {
    return <Redirect to="/login" />;
  }
  if (result.status === "error" || result.data === undefined) {
    return <ErrorMessage error={result.error} />;
  }
  const user = result.data.data;
  if (
    !user.isPaid &&
    user.trialEnd != null &&
    fromUnixTime(user.trialEnd).valueOf() < new Date().valueOf()
  ) {
    showToast("Your trial has expired!", { type: "success" });
    return <Redirect to="/account/billing" />;
  }
  if (user.primaryBusinessUuid == null) {
    return <Redirect to="/businesses/new" />;
  }
  return props.children;
}
