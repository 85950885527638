import * as React from "react";
import cx from "classcat";
import styles from "./Button.module.css";
import buttonPrimaryStyles from "./ButtonPrimary.module.css";
import buttonSmPrimaryStyles from "./ButtonSmPrimary.module.css";
import buttonDangerStyles from "./ButtonDanger.module.css";
import buttonSecondaryStyles from "./ButtonSecondary.module.css";
import buttonSecondaryDangerStyles from "./ButtonSecondaryDanger.module.css";
import buttonSmSecondaryDangerStyles from "./ButtonSmSecondaryDanger.module.css";
import buttonXxsSecondaryDangerStyles from "./ButtonXxsSecondaryDanger.module.css";
import buttonTransparentPrimaryStyles from "./ButtonTransparentPrimary.module.css";
import buttonSmTransparentPrimaryStyles from "./ButtonSmTransparentPrimary.module.css";
import buttonTransparentNeutralStyles from "./ButtonTransparentNeutral.module.css";
import buttonSmNeutralStyles from "./ButtonSmNeutral.module.css";
import buttonXsNeutralStyles from "./ButtonXsNeutral.module.css";
import buttonXxsNeutralStyles from "./ButtonXxsNeutral.module.css";
import linkStyles from "./Link.module.css";

export { ButtonActivityIndicator } from "./ButtonActivityIndicator";
export { ButtonLayout } from "./ButtonLayout";

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    variant?:
      | "primary"
      | "smPrimary"
      | "danger"
      | "secondary"
      | "secondaryDanger"
      | "smSecondaryDanger"
      | "xxsSecondaryDanger"
      | "transparentPrimary"
      | "smTransparentPrimary"
      | "transparentNeutral"
      | "smNeutral"
      | "xsNeutral"
      | "xxsNeutral"
      | "text"
      | "textSm";
  }
>((props, ref) => {
  const { variant = "primary", ...restProps } = props;
  return (
    <button
      ref={ref}
      // https://stackoverflow.com/questions/3885018/active-pseudo-class-doesnt-work-in-mobile-safari
      onTouchStart={() => {}}
      className={cx([
        ["text", "textSm"].includes(variant) && linkStyles.link,
        variant === "textSm" && linkStyles.linkSm,
        !["text", "textSm"].includes(variant) && styles.button,
        variant === "primary" && buttonPrimaryStyles.buttonPrimary,
        variant === "smPrimary" && buttonSmPrimaryStyles.buttonSmPrimary,
        variant === "danger" && buttonDangerStyles.buttonDanger,
        variant === "secondary" && buttonSecondaryStyles.buttonSecondary,
        variant === "secondaryDanger" &&
          buttonSecondaryDangerStyles.buttonSecondaryDanger,
        variant === "smSecondaryDanger" &&
          buttonSmSecondaryDangerStyles.buttonSmSecondaryDanger,
        variant === "xxsSecondaryDanger" &&
          buttonXxsSecondaryDangerStyles.buttonXxsSecondaryDanger,
        variant === "transparentPrimary" &&
          buttonTransparentPrimaryStyles.buttonTransparentPrimary,
        variant === "smTransparentPrimary" &&
          buttonSmTransparentPrimaryStyles.buttonSmTransparentPrimary,
        variant === "transparentNeutral" &&
          buttonTransparentNeutralStyles.buttonTransparentNeutral,
        variant === "smNeutral" && buttonSmNeutralStyles.buttonSmNeutral,
        variant === "xsNeutral" && buttonXsNeutralStyles.buttonXsNeutral,
        variant === "xxsNeutral" && buttonXxsNeutralStyles.buttonXxsNeutral,
      ])}
      {...restProps}
    />
  );
});

export function ButtonText(props: { children: string }) {
  return <>{props.children}</>;
}
