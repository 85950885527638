import * as React from "react";
import { MenuButtonBase } from "./lib/MenuButton";
import styles from "./HeaderMenuButton.module.css";

export function HeaderMenuButton(
  props: React.ComponentProps<typeof MenuButtonBase> & { noArrow?: boolean }
) {
  const { noArrow = false, children, ...rest } = props;
  return (
    <MenuButtonBase className={styles.menuButton} {...rest}>
      <div className={styles.menuButtonLayout}>
        {children}
        {noArrow ? null : (
          <span aria-hidden className={styles.menuButtonArrow}>
            ▾
          </span>
        )}
      </div>
    </MenuButtonBase>
  );
}
