import * as React from "react";
import cx from "classcat";
import { NavLink } from "react-router-dom";
import styles from "./NavTabs.module.css";

export function NavTabList(props: { children: any }) {
  return <nav className={styles.navList}>{props.children}</nav>;
}

export function NavTab(
  props: React.ComponentProps<typeof NavLink> & { disabled?: boolean }
) {
  const { children, exact = true, disabled = false, ...rest } = props;
  return (
    <NavLink
      className={cx([styles.link, disabled && styles.linkDisabled])}
      activeClassName={styles.linkActive}
      exact={exact}
      {...rest}
    >
      <div className={styles.navTabLayout}>{children}</div>
    </NavLink>
  );
}
