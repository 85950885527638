import * as React from "react";
import cx from "classcat";
import styles from "./Input.module.css";
import inputOutlineStyles from "./InputOutline.module.css";
import inputFilledStyles from "./InputFilled.module.css";
import formControlStyles from "./FormControl.module.css";
import labelStyles from "./Label.module.css";
import { FormControlHelperText } from "./FormControlHelperText";

export const Input = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input"> & {
    label: string | null;
    labelEnd?: React.ReactNode;
    error?: string | undefined;
    helperText?: string | undefined;
    showRequiredIndicator?: boolean;
    variant?: "filled" | "outline";
    size?: "sm";
  }
>((props, ref) => {
  const {
    label,
    labelEnd,
    error,
    helperText,
    showRequiredIndicator = true,
    variant = "filled",
    size,
    ...rest
  } = props;
  const input = (
    <input
      ref={ref}
      className={cx([
        styles.input,
        size === "sm" && styles.inputSm,
        variant === "outline" && inputOutlineStyles.inputOutline,
        variant === "filled" && inputFilledStyles.inputFilled,
        error && formControlStyles.invalid,
      ])}
      {...rest}
    />
  );
  const text = error || helperText;
  const textComp = text ? (
    <FormControlHelperText isError={!!error}>{text}</FormControlHelperText>
  ) : null;
  if (!label) {
    return (
      <div className={styles.container}>
        {input}
        {textComp}
      </div>
    );
  }
  return (
    <div>
      <label className={labelStyles.label}>
        <div
          className={cx([
            labelStyles.labelInner,
            labelEnd && labelStyles.labelInnerWithEnd,
          ])}
        >
          <div className={labelStyles.labelTextContainer}>
            <span className={labelStyles.labelText}>{label}</span>
            {rest.required && showRequiredIndicator ? (
              <span
                aria-hidden
                className={labelStyles.labelTextRequiredIndicator}
              >
                *
              </span>
            ) : null}
          </div>
          {labelEnd ? (
            <div className={labelStyles.labelEndContainer}>{labelEnd}</div>
          ) : null}
        </div>
        {input}
      </label>
      {textComp}
    </div>
  );
});
