import * as React from "react";
import { Button } from "./lib/Button";
import styles from "./DefaultErrorBoundary.module.css";

export class DefaultErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.root}>
          <h1 className={styles.heading}>Something went wrong :(</h1>
          <div className={styles.subHeading}>
            Please reload the page, or sign out.
          </div>
          <Button
            type="button"
            onClick={() => {
              // Can't use useAuth, since it's below in the tree
              window.localStorage.clear();
              window.location.href = "/login";
            }}
          >
            Sign Out
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}
