import "@reach/menu-button/styles.css";
import styles from "./MenuButton.module.css";
import * as React from "react";
import { MenuButton as MenuButtonBase } from "@reach/menu-button";
import cx from "classcat";
import buttonStyles from "./Button.module.css";
import buttonPrimaryStyles from "./ButtonPrimary.module.css";
import buttonSmNeutralStyles from "./ButtonSmNeutral.module.css";
import buttonXsNeutralStyles from "./ButtonXsNeutral.module.css";

export {
  Menu,
  MenuButton as MenuButtonBase,
  MenuList,
  MenuItems,
  MenuItem,
  MenuLink,
  MenuPopover,
} from "@reach/menu-button";

export const MenuButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof MenuButtonBase> & {
    variant?: "primary" | "smNeutral" | "xsNeutral";
    noArrow?: boolean;
  }
>((props, ref) => {
  const { variant = "smNeutral", noArrow = false, children, ...rest } = props;
  return (
    <MenuButtonBase
      ref={ref}
      // https://stackoverflow.com/questions/3885018/active-pseudo-class-doesnt-work-in-mobile-safari
      onTouchStart={() => {}}
      className={cx([
        buttonStyles.button,
        variant === "primary" && buttonPrimaryStyles.buttonPrimary,
        variant === "smNeutral" && buttonSmNeutralStyles.buttonSmNeutral,
        variant === "xsNeutral" && buttonXsNeutralStyles.buttonXsNeutral,
      ])}
      {...rest}
    >
      {children}
      {noArrow ? null : (
        <span aria-hidden className={styles.menuButtonArrow}>
          ▾
        </span>
      )}
    </MenuButtonBase>
  );
});

export function MenuItemTextDanger(props: { children: string }) {
  return <span className={styles.menuItemTextDanger}>{props.children}</span>;
}
