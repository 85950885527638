import * as React from "react";
import cx from "classcat";
import styles from "./FormActions.module.css";

export function FormActions(props: {
  children: React.ReactNode;
  marginBlockStart?: "xxs" | "xs" | "sm" | "md" | "lg";
}) {
  const { children, marginBlockStart } = props;
  return (
    <div
      className={cx([
        styles.root,
        marginBlockStart === "xxs" && styles.xxs,
        marginBlockStart === "xs" && styles.xs,
        marginBlockStart === "sm" && styles.sm,
        marginBlockStart === "md" && styles.md,
        marginBlockStart === "lg" && styles.lg,
      ])}
    >
      {children}
    </div>
  );
}
