import * as React from "react";
import { useQuery, useMutation, queryCache } from "react-query";
import cloneDeep from "lodash.clonedeep";
import { Menu } from "./lib/MenuButton";
import { View } from "./lib/View";
import { Text } from "./lib/Text";
import { FlatList } from "./lib/FlatList";
import { useSDK } from "../shared/useSDK";
import { Delay } from "./lib/Delay";
import { ActivityIndicator } from "./lib/ActivityIndicator";
import { Button, ButtonActivityIndicator } from "./lib/Button";
import { MdNotifications } from "react-icons/md";
import { HeaderMenuButton } from "./HeaderMenuButton";
import styles from "./HeaderNotifications.module.css";
import { HeaderMenuPopover } from "./HeaderMenuPopover";
import { HeaderBadge } from "./HeaderBadge";
import { formatNumber } from "../shared/formatNumber";
import { FormActions } from "./FormActions";
import type { SDK } from "../sdk";
import { ThenArg } from "../shared/ThenArg";
import { Unarray } from "../shared/Unarray";
import { showToast } from "./lib/Toast";

type IncomingInvite = Unarray<
  ThenArg<ReturnType<SDK["getInviteLists"]>>["data"]["incoming"]
>;

export function HeaderNotifications() {
  const { sdk } = useSDK();
  const query = useQuery(["inviteList"], (key) => sdk.getInviteLists(), {
    enabled: false,
  });
  React.useEffect(() => {
    const id = setInterval(() => {
      query.refetch();
    }, 20000);
    return () => {
      clearInterval(id);
    };
  }, [query]);

  return (
    <Menu>
      <HeaderMenuButton>
        <MdNotifications />
        <NotificationCountBadge />
      </HeaderMenuButton>
      <HeaderMenuPopover>
        <NotificationList />
      </HeaderMenuPopover>
    </Menu>
  );
}

function NotificationList() {
  const { sdk } = useSDK();
  const result = useQuery(["inviteList"], (key) => sdk.getInviteLists());
  function _render() {
    if (result.status === "loading") {
      return (
        <View style={styles.listLoading}>
          <Delay>
            <ActivityIndicator />
          </Delay>
        </View>
      );
    }
    if (result.status === "error" || result.data === undefined) {
      return (
        <View style={styles.listEmpty}>
          <Text>Failed to load notifications</Text>
        </View>
      );
    }
    const list = result.data.data.incoming;
    return (
      <FlatList
        keyExtractor={(x) => x.uuid}
        data={list}
        ListEmptyComponent={
          <View style={styles.listEmpty}>
            <Text>No notifications</Text>
          </View>
        }
        ItemSeparatorComponent={() => <View style={styles.listItemSeparator} />}
        renderItem={({ item }) => {
          return <ListItem item={item} />;
        }}
      />
    );
  }
  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.heading}>Notifications</Text>
      </View>
      <View style={styles.listContainer}>{_render()}</View>
    </View>
  );
}

function ListItem(props: { item: IncomingInvite }) {
  const { item } = props;
  const { sdk } = useSDK();
  const [acceptInvite, acceptInviteResult] = useMutation(
    (variables: { params: Parameters<typeof sdk.acceptInvite>[0] }) => {
      return sdk.acceptInvite(variables.params);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.setQueryData(
          ["inviteList"],
          (
            old: ThenArg<ReturnType<SDK["getInviteLists"]>> | null | undefined
          ) => {
            if (old == null) {
              return null;
            }
            const next = cloneDeep(old);
            const i = next.data.incoming.findIndex(
              (x) => x.uuid === variables.params.inviteUuid
            );
            if (i !== -1) {
              next.data.incoming.splice(i, 1);
            }
            return next;
          }
        );
      },
      onError: (reason) => {
        showToast("Something went wrong. Please try again.", { type: "error" });
      },
    }
  );
  return (
    <View style={styles.listItem}>
      <span className={styles.listItemTextEmphasis}>
        {[item.fromUser.firstName, item.fromUser.lastName].join(" ")}
      </span>
      <span>{" is sharing "}</span>
      <span className={styles.listItemTextSubEmphasis}>
        {item.type.toLocaleLowerCase()}
      </span>
      <span> </span>
      <span className={styles.listItemTextEmphasis}>{item.itemName}</span>
      <span>{" with you."}</span>
      <FormActions marginBlockStart="xxs">
        <Button
          type="button"
          onClick={() => {
            acceptInvite({ params: { inviteUuid: item.uuid } });
          }}
          disabled={acceptInviteResult.isLoading}
          variant="smPrimary"
        >
          {acceptInviteResult.isLoading ? (
            <ButtonActivityIndicator />
          ) : (
            "Accept"
          )}
        </Button>
      </FormActions>
    </View>
  );
}

function NotificationCountBadge() {
  const { sdk } = useSDK();
  const result = useQuery(["inviteList"], (key) => sdk.getInviteLists());
  if (result.status === "loading") {
    return null;
  }
  if (result.status === "error" || result.data === undefined) {
    return null;
  }
  const count = result.data.data.incoming.length;
  if (count === 0) {
    return null;
  }
  return (
    <View style={styles.notificationCountBadgeContainer}>
      <HeaderBadge>{formatNumber(count)}</HeaderBadge>
    </View>
  );
}
