import * as React from "react";
import cx from "classcat";
import styles from "./FormFields.module.css";

export function FormFields(props: { children: React.ReactNode; gap?: "sm" }) {
  return (
    <div className={cx([styles.fields, props.gap === "sm" && styles.fieldsSm])}>
      {props.children}
    </div>
  );
}

export function FormFieldsColumns(props: {
  children: React.ReactNode;
  gap?: "sm";
}) {
  return (
    <div
      className={cx([
        styles.fieldsColumns,
        props.gap === "sm" && styles.fieldsColumnsSm,
      ])}
    >
      {props.children}
    </div>
  );
}
