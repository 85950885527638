import * as React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Formik, Form } from "formik";
import { Input } from "./lib/Input";
import {
  Button,
  ButtonActivityIndicator,
  ButtonLayout,
  ButtonText,
} from "./lib/Button";
import { FormFields } from "./lib/FormFields";
import { useSDK } from "../shared/useSDK";
import {
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogActions,
} from "./lib/AlertDialogDark";
import type { SDK } from "../sdk";
import { ThenArg } from "../shared/ThenArg";
import { showToast } from "./lib/Toast";
import { IconShare } from "./IconShare";

type Template = ThenArg<ReturnType<SDK["getTemplate"]>>["data"];

export function HeaderActionsTemplate() {
  const { sdk } = useSDK();
  const params = useParams<{ uuid: string }>();
  const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);
  function toggleShareDialog() {
    setIsShareDialogOpen((x) => !x);
  }
  const templateResult = useQuery(
    ["template", params.uuid],
    (key, uuid: string) => sdk.getTemplate({ templateUuid: uuid })
  );
  if (templateResult.status === "loading") {
    return null;
  }
  if (templateResult.status === "error" || templateResult.data === undefined) {
    return null;
  }
  const template = templateResult.data.data;
  return (
    <>
      {isShareDialogOpen ? (
        <ShareTemplateDialog
          template={template}
          toggleDialog={toggleShareDialog}
        />
      ) : null}
      <Button type="button" onClick={toggleShareDialog} variant="xxsNeutral">
        <ButtonLayout>
          <IconShare />
          <ButtonText>Share</ButtonText>
        </ButtonLayout>
      </Button>
    </>
  );
}

function ShareTemplateDialog(props: {
  template: Template;
  toggleDialog: () => void;
}) {
  const { sdk } = useSDK();
  const { toggleDialog, template } = props;
  const [createInvite, createInviteResult] = useMutation(
    (variables: { data: Parameters<typeof sdk.createInvite>[1] }) =>
      sdk.createInvite(null, variables.data),
    {
      onSuccess: (data) => {
        toggleDialog();
        showToast(`Template shared with ${data.data.toEmail}!`, {
          type: "success",
        });
      },
      onError: (reason: any) => {
        showToast(
          reason?.error?.message || "Something went wrong. Please try again.",
          { type: "error" }
        );
      },
    }
  );
  const leastDestructiveRef = React.useRef<HTMLButtonElement>(null);
  return (
    <AlertDialogOverlay
      onDismiss={toggleDialog}
      leastDestructiveRef={leastDestructiveRef}
    >
      <AlertDialogContent>
        <Formik
          initialValues={{
            toEmail: "",
          }}
          onSubmit={(values, f) => {
            createInvite({
              data: {
                type: "TEMPLATE",
                toEmail: values.toEmail,
                entityUuid: template.uuid,
                itemName: template.name,
              },
            });
          }}
        >
          {(f) => (
            <Form>
              <AlertDialogHeader>
                <AlertDialogLabel>
                  Share Template with Another DocuMT User
                </AlertDialogLabel>
              </AlertDialogHeader>
              <AlertDialogBody>
                <AlertDialogDescription></AlertDialogDescription>
                <FormFields>
                  <Input
                    required
                    type="email"
                    label="Email to Send Template Sharing Invite"
                    placeholder="Email"
                    name="toEmail"
                    value={f.values.toEmail}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    error={(f.touched.toEmail && f.errors.toEmail) || undefined}
                    helperText="Once accepted, the template will be copied to the shared user. They will not be able to edit this template - they will be editing their own copy."
                  />
                </FormFields>
              </AlertDialogBody>
              <AlertDialogActions>
                <Button
                  ref={leastDestructiveRef}
                  type="button"
                  onClick={toggleDialog}
                  variant="transparentPrimary"
                >
                  Cancel
                </Button>
                <Button type="submit">
                  {createInviteResult.isLoading ? (
                    <ButtonActivityIndicator />
                  ) : (
                    "Send"
                  )}
                </Button>
              </AlertDialogActions>
            </Form>
          )}
        </Formik>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
}
