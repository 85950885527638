import * as React from "react";
import cx from "classcat";
import styles from "./Avatar.module.css";

export type AvatarProps = {
  size?: "xs" | "sm" | "lg" | "xl" | "xxl";
  fallback: string;
  fallbackVariant?: "outline";
  shape?: "rectangular";
};

export function Avatar(props: React.ComponentProps<"img"> & AvatarProps) {
  const {
    alt,
    size,
    shape,
    fallback,
    fallbackVariant,
    className,
    ...restProps
  } = props;
  const [shouldShowFallback, setShouldShowFallback] = React.useState(
    !restProps.src
  );
  if (shouldShowFallback) {
    const content = fallback.length === 0 ? "U" : fallback.slice(0, 1);
    return (
      <div
        className={cx([
          styles.avatar,
          size === "xs" && styles.xs,
          size === "sm" && styles.sm,
          size === "lg" && styles.lg,
          size === "xl" && styles.xl,
          size === "xxl" && styles.xxl,
          styles.fallback,
          fallbackVariant === "outline" && styles.fallbackOutline,
          shape === "rectangular" && styles.rectangular,
          className,
        ])}
      >
        {content}
      </div>
    );
  }
  return (
    <img
      alt={alt}
      className={cx([
        styles.avatar,
        size === "xs" && styles.xs,
        size === "sm" && styles.sm,
        size === "lg" && styles.lg,
        size === "xl" && styles.xl,
        size === "xxl" && styles.xxl,
        shape === "rectangular" && styles.rectangular,
        className,
      ])}
      onError={(event) => {
        setShouldShowFallback(true);
      }}
      {...restProps}
    />
  );
}

// https://material-ui.com/components/avatars/#grouped
export function AvatarGroup(props: {
  children: React.ReactNode;
  EmptyComponent?: React.ReactNode;
  max?: number;
}) {
  const arr = React.Children.toArray(props.children);
  const inner = props.max == null ? arr : arr.slice(0, props.max);
  if (arr.length === 0) {
    if (props.EmptyComponent !== undefined) {
      return props.EmptyComponent;
    }
    return null;
  }
  return <div className={styles.avatarGroup}>{inner}</div>;
}

export function AvatarGroupEmpty(props: { text?: string }) {
  return (
    <div className={styles.avatarGroupEmpty}>
      <div className={styles.avatarGroupEmptyText}>
        {props.text ?? "Nothing to show"}
      </div>
    </div>
  );
}
