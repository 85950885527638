import * as React from "react";

type User = { uuid: string };

export const UserContext = React.createContext<{
  user: User;
}>({
  // @ts-ignore
  user: null,
});
