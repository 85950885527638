import fromUnixTime from "date-fns/fromUnixTime";
import type { SDK } from "../sdk";
import { ThenArg } from "./ThenArg";

type Session = ThenArg<ReturnType<SDK["getSession"]>>["data"];

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

const timeFormatter = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
});

export function getSessionName(session: Session) {
  if (session.startTime == null || session.endTime == null) {
    return "--";
  }
  const date = fromUnixTime(session.startTime);
  const endDate = fromUnixTime(session.endTime);
  return dateFormatter.format(date) + " @ " + timeFormatter.format(date) + "-" + timeFormatter.format(endDate);
}
