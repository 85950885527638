import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.css";

export function Breadcrumb(props: { children: React.ReactNode }) {
  const arr = React.Children.toArray(props.children);
  return (
    <div className={styles.breadcrumb}>
      {React.Children.map(props.children, (x, i) => {
        const count = React.Children.count(props.children);
        if (i === count - 1 || (i < count - 1 && arr[i + 1] == null)) {
          return x;
        }
        return (
          <>
            {x}
            <BreadcrumbSeparator />
          </>
        );
      })}
    </div>
  );
}

export function BreadcrumbLink(props: { children: React.ReactNode; to: any }) {
  return (
    <Link to={props.to} className={styles.breadcrumbLink}>
      {props.children}
    </Link>
  );
}

export function BreadcrumbAligner(props: { children: any }) {
  return <div className={styles.aligner}>{props.children}</div>;
}

function BreadcrumbSeparator() {
  return <div className={styles.breadcrumbSeparator}>{"\u2044"}</div>;
}
