import { shortenLargeNumber } from "./shortenLargeNumber";

const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 1,
});

export function formatNumber(x: number) {
  if (Math.abs(x) >= 1000) {
    return shortenLargeNumber(x);
  }
  return numberFormatter.format(x);
}
