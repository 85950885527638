import * as React from "react";
import cx from "classcat";
import { Link as RRLink } from "react-router-dom";
import styles from "./Link.module.css";
import buttonStyles from "./Button.module.css";
import buttonPrimaryStyles from "./ButtonPrimary.module.css";
import buttonSecondaryStyles from "./ButtonSecondary.module.css";
import buttonSecondaryDangerStyles from "./ButtonSecondaryDanger.module.css";
import buttonTransparentPrimaryStyles from "./ButtonTransparentPrimary.module.css";
import buttonSmNeutralStyles from "./ButtonSmNeutral.module.css";
import buttonXxsNeutralStyles from "./ButtonXxsNeutral.module.css";

export { ButtonLayout as LinkLayout } from "./ButtonLayout";

export const Link = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentProps<typeof RRLink> & {
    variant?:
      | "text"
      | "textSm"
      | "buttonPrimary"
      | "buttonSecondary"
      | "buttonSecondaryDanger"
      | "buttonTransparentPrimary"
      | "buttonSmNeutral"
      | "buttonXxsNeutral";
  }
>((props, ref) => {
  const { variant = "text", ...restProps } = props;
  return (
    <RRLink
      ref={ref}
      // https://stackoverflow.com/questions/3885018/active-pseudo-class-doesnt-work-in-mobile-safari
      onTouchStart={() => {}}
      className={cx([
        ["text", "textSm"].includes(variant) && styles.link,
        variant === "textSm" && styles.linkSm,
        !["text", "textSm"].includes(variant) && buttonStyles.button,
        variant === "buttonPrimary" && buttonPrimaryStyles.buttonPrimary,
        variant === "buttonSecondary" && buttonSecondaryStyles.buttonSecondary,
        variant === "buttonSecondaryDanger" &&
          buttonSecondaryDangerStyles.buttonSecondaryDanger,
        variant === "buttonTransparentPrimary" &&
          buttonTransparentPrimaryStyles.buttonTransparentPrimary,
        variant === "buttonSmNeutral" && buttonSmNeutralStyles.buttonSmNeutral,
        variant === "buttonXxsNeutral" &&
          buttonXxsNeutralStyles.buttonXxsNeutral,
      ])}
      {...restProps}
    />
  );
});

export function LinkText(props: { children: string }) {
  return <>{props.children}</>;
}
